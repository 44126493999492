'use strict';

var Headroom = require('headroom.js');

var header = function () {
  var headerEl = document.querySelector('.js-fixed-header');
  var INITIAL_OFFSET = 40;
  var TOLERANCE_UP = 0;
  var TOLERANCE_DOWN = 5;

  var headroomOptions = {
    offset: INITIAL_OFFSET,
    tolerance : {
      up : TOLERANCE_UP,
      down : TOLERANCE_DOWN
    },
    classes : {
      // when element is initialised
      initial : "headroom",
      // when scrolling up
      pinned : "is-pinned",
      // when scrolling down
      unpinned : "is-unpinned",
      // when above offset
      top : "at-top",
      // when below offset
      notTop : "not-at-top",
      // when at bottom of scoll area
      bottom : "at-bottom",
      // when not at bottom of scroll area
      notBottom : "not-at-bottom"
    }
  }

  var stickyHeader = new Headroom(headerEl, headroomOptions);
  stickyHeader.init();
};

module.exports = header;
