'use strict';

var $ = require('jquery');

var showHide = function () {
  var $html = $('html');
  var $body = $('body');
  var $triggers = $('.js-show-hide');
  var $containers = $('.js-show-hide-container');
  var $containerLinks = $('.js-show-hide-container');
  var mqMedium = '(min-width: 768px)';

  if (matchMedia(mqMedium).matches) {
    $body.on('click', function(e) {
      if ($html.hasClass('nav-popup-open')) {
        $html.removeClass('nav-popup-open');
        $containers.removeClass('is-visible');
      }
    });

    $containerLinks.find('a').on('click', function(e) {
      e.stopPropagation();
    });
  }

  $triggers.each(function(e) {
    var $this = $(this);
    var $elementToToggle = $($this.data('element'));

    $this.on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      $containers.removeClass('is-visible');

      if ($html.hasClass('nav-popup-open')) {
        $elementToToggle.removeClass('is-visible');
        $html.removeClass('nav-popup-open');
      } else {
        $elementToToggle.addClass('is-visible');
        $html.addClass('nav-popup-open');
      }
    });
  });
};

module.exports = showHide;
