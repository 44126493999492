'use strict';

var $ = require('jquery');
var slick = require('slick-carousel');

var quotes = function () {
  var $quoteSlider = $('.js-quote-slider');

  $quoteSlider.each(function() {
    $(this).slick({
      dots: true,
      speed: 500,
      autoplay: false,
      autoplaySpeed: 8000,
      arrows: false,
      draggable: false
    });
  });
};

module.exports = quotes;
