'use strict';

var $ = require('jquery');

var selectFocus = function () {
  var $customSelect = $('.js-select-focus');

  $customSelect.each(function() {
    var $selectParent = $(this);
    var $realSelectEl = $selectParent.find('select');
    var ZERO = 0;

    $realSelectEl.on('change', function() {
      var notOptionLabel = $(this).find('option:selected:not([data-option-label])').length;

      if ((this.value != '' || this.value > ZERO || this.value.length > ZERO) && notOptionLabel) {
        $selectParent.addClass('option-selected');
      } else {
        $selectParent.removeClass('option-selected');
      }
    });

    $realSelectEl.on('focus', function() {
      $selectParent.addClass('is-focussed');
    }).on('blur', function() {
      $selectParent.removeClass('is-focussed');
    });
  });
};

module.exports = selectFocus;
