'use strict';

var $ = require('jquery');
var ScrollMagic = require("scrollmagic");
var velocity = require("velocity-animate");

var magic = function () {
  var $window = $(window);
  var $document = $(document);
  var alreadyInitialised = false;
  var destroyWindowResizeEvent = false;
  var mediaQueryString = '(min-width: 1024px)';
  var $contact = $('.c-contact');
  var footer = document.querySelector('.c-footer');
  var footerHeight = footer.offsetHeight;
  var ANIMATION_DURATION = 150;
  var BOTTOM_TRIGGER_HOOK = 1;
  var DOCK_OFFSET = 200;
  var FIXED_RIGHT_BOTTOM = 20;
  var ONE = 1;
  var ZERO = 0;
  var HUNDRED_PERCENT = '100%';
  var isAppropriate = ($document.height() - footerHeight - DOCK_OFFSET) > $window.height();

  $('html').addClass('page-loaded');

  var controller = new ScrollMagic.Controller();

  var checkWindowSize = function() {
    if (matchMedia(mediaQueryString).matches) {
      setupBehaviour();
    }
  };

  var setupBehaviour = function() {
    if (alreadyInitialised == true) {
      if (destroyWindowResizeEvent == false) {
        $window.off('resize');
        destroyWindowResizeEvent = true;
      }

      return;
    }

    alreadyInitialised = true;

    if (!isAppropriate) {
      return;
    }

    $contact.addClass('is-floating is-collapsed')

    var docked = new ScrollMagic.Scene({
      offset: -DOCK_OFFSET,
      duration: footerHeight,
      triggerElement: '.c-footer'})
        .triggerHook(BOTTOM_TRIGGER_HOOK)
        .addTo(controller);

    docked.on("start", function (event) {
      var dockedBottom = 0;

      if (event.scrollDirection == 'FORWARD') {
        // Position c-contact in footer and expand content
        $contact
        .velocity({
          bottom: [dockedBottom, FIXED_RIGHT_BOTTOM],
          translateY: [HUNDRED_PERCENT, ZERO],
          opacity: [ZERO, ONE]
        }, {
          duration: ANIMATION_DURATION,
          complete: function() {
            $contact.removeClass('is-collapsed is-floating').css({'bottom': '', 'right': '', 'transform': 'translateY(0)', 'opacity': 1})
          }
        });
      } else {
        // Position c-contact to bottom of window and minimise content
        $contact.addClass('is-collapsed is-floating').css({'bottom': dockedBottom, 'right': FIXED_RIGHT_BOTTOM, 'opacity': 0, 'transform': 'translateY(' + HUNDRED_PERCENT + ')'})
        $contact
        .velocity({
          bottom: [FIXED_RIGHT_BOTTOM, dockedBottom],
          translateY: [ZERO, HUNDRED_PERCENT],
          opacity: [ONE, ZERO]
        }, {
          duration: ANIMATION_DURATION,
          complete: function() {
            $contact.css('bottom', '');
          }
        });
      }
    });

  };

  var checkWindowSizeOnResize = function() {
    if (alreadyInitialised == true) {
      return;
    }

    $window.on('resize', function() {
      checkWindowSize();
    });
  };

  checkWindowSize();
  checkWindowSizeOnResize();
};

module.exports = magic
