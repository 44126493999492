/*
  Project: LittleMan
  Author: James Barr
 */

window.jQuery = window.$ = require('jquery');

var header = require('./header.js')
var nav = require('./nav.js')
var form = require('./form.js')
var selectFocus = require('./select-focus.js')
var quotesSlider = require('./quotes-slider.js')
var magic = require('./magic.js')
var clients = require('./clients.js')
var showHide = require('./show-hide.js')

header();
nav();
form();
selectFocus();
quotesSlider();
magic();
clients();
showHide();
