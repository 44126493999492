'use strict';

var $ = require('jquery');

var nav = function () {
  var $html = $('html');
  var navToggleEl = document.querySelector('.js-nav-toggle');

  navToggleEl.addEventListener('click', function(e) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    if ($html.hasClass('u-nav-open')) {
      $html.removeClass('u-nav-open');
    } else {
      $html.addClass('u-nav-open');
    }
  });
};

module.exports = nav;
