'use strict';

var $ = require('jquery');
var imagesLoaded = require('imagesloaded');
var Isotope = require('isotope-layout');

var clients = function() {
  var filtersArray = [];
  var $filter = $('.js-client-filter');
  var $filterA = $filter.find('a');
  var classListingIsotope;

  var init = function() {
    var gridEl = document.querySelector('.js-client-grid');

    if (gridEl == null || gridEl == 'undefined') {
      return;
    }
    
    imagesLoaded(gridEl, function() {
      classListingIsotope = new Isotope( gridEl, {
        itemSelector: '.js-client-grid-item',
        filter: ':not(.is-hidden)',
        masonry: {
          columnWidth: '.js-client-grid-item'
        }
      });

      classListingIsotope.once( 'layoutComplete', function() {
        $('.js-client-grid-item').removeClass('is-hidden');
      });

      classListingIsotope.layout();
      filterClickEvent();
    });
  };

  init();

  var filterClickEvent = function() {
    $filterA.on('click', function(event) {
      var $self = $(this);
      var $parent = $self.parent();
      var filter = $parent.attr('data-filter');

      event.preventDefault();
      
      if (filter == 'all') {
        $filter.removeClass('is-selected');
        $parent.addClass('is-selected');
        filtersArray = [];
        addFilter(filter);
      } else {
        if ($parent.hasClass('is-selected')) {
          $parent.removeClass('is-selected');
          removeFilter(filter);

          // If there's no filter selected, select 'all'
          if ($('.js-client-filter.is-selected').length === 0) {
            filtersArray = [];
            addFilter('all');
            $filter.first().addClass('is-selected');
          }
        } else {
          $filter.first().removeClass('is-selected');
          removeFilter('all', false);
          $parent.addClass('is-selected');
          addFilter(filter);
        }
      }
    });
  };

  var addFilter = function(filter) {
    filtersArray.push(filter);
    applyFilter();
  };

  var removeFilter = function(filter, applyFilters) {
    /**
     * Find the index of the filter in the filtersArray array.
     * Remove from array.
     */
    var indexToSplice = filtersArray.indexOf(filter);

    if (indexToSplice != -1) {
      filtersArray.splice(indexToSplice, 1); // at indexToSplice, remove 1
    }

    if (applyFilters != false) {
      applyFilter();
    }
  };

  var applyFilter = function() {
    var selectorForFiltering = '';

    for (var i = 0; i < filtersArray.length; i ++) {
      if (selectorForFiltering.length === 0) {
        if (filtersArray[i] == 'all') {
          selectorForFiltering = '[data-categories]';
        } else {
          selectorForFiltering = '[data-categories*="' + filtersArray[i] + '"]';
        }
      } else {
        if (filtersArray[i] == 'all') {
          selectorForFiltering += ', ' + '[data-categories]';
        } else {
          selectorForFiltering += ', ' + '[data-categories*="' + filtersArray[i] + '"]';
        }
      }
    }

    selectorForFiltering.replace('*=""', '')
    classListingIsotope.arrange({ filter: $(selectorForFiltering) })
  }
};

module.exports = clients;
